<template>
    <div class="assess-header">
        <div class="steps">
            <div
                class="step-single"
                v-for="(item, index) in headerData"
                :key="index"
            >
                <div class="line"></div>
                <div
                    class="step-content"
                    :style="{
                        cursor: currentStep === item.step ? '' : 'pointer',
                    }"
                    @click="handleStepClick(item)"
                >
                    <div
                        class="step-title"
                        :style="{
                            background:
                                currentStep === item.step ? '#679fc0' : '#fff',
                            color:
                                currentStep === item.step ? '#fff' : '#606266',
                        }"
                    >
                        {{ item.title }}
                    </div>
                    <div
                        class="step-step"
                        :style="{
                            color:
                                currentStep === item.step
                                    ? '#679fc0'
                                    : '#606266',
                        }"
                    >
                        {{ item.step }}
                    </div>
                </div>
                <div class="line"></div>
            </div>
        </div>
        <div class="detail">
            <div class="detail-icon">
                <div class="detail-i">i</div>
            </div>
            <div class="detail-text">
                <div class="detail-title">{{ dataSource.title || "" }}</div>
                <div class="detail-content">{{ dataSource.content || "" }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "assess_header",
    data() {
        return {
            headerData: [
                {
                    step: 1,
                    title: "评估对象",
                    routeName: "assess_target_grid",
                },
                {
                    step: 2,
                    title: "评估记录",
                    routeName: "assess_record_grid",
                },
                {
                    step: 3,
                    title: "评估配置",
                    routeName: "assess_config_grid",
                },
            ],
        };
    },
    props: {
        currentStep: {
            type: Number,
            default: 1,
        },
        dataSource: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        handleStepClick(val) {
            if (this.currentStep === val.step) return;
            this.$router.push({
                name: val.routeName,
                query: {},
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.assess-header {
    $lightBlue: #679fc0;
    $stepContentHeight: 36px;
    $detailHeight: 70px;
    width: 100%;
    height: 150px;
    .steps {
        width: 100%;
        height: 80px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        .step-single {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            .step-content {
                position: relative;
                height: $stepContentHeight;
                .step-title {
                    width: 150px;
                    height: $stepContentHeight;
                    border: 1px solid $lightBlue;
                    text-align: center;
                    line-height: $stepContentHeight - 2px;
                    font-size: 18px;
                }
                .step-step {
                    position: absolute;
                    top: 0;
                    left: $stepContentHeight/2 * (-1);
                    width: $stepContentHeight;
                    height: $stepContentHeight;
                    border: 1px solid $lightBlue;
                    border-radius: 50%;
                    text-align: center;
                    line-height: $stepContentHeight - 2px;
                    font-size: 18px;
                    font-weight: bold;
                    background: #fff;
                }
            }
            .line {
                width: 150px;
                height: 0;
                border-top: 1px solid $lightBlue;
            }
        }
    }
    .detail {
        width: 100%;
        height: $detailHeight;
        border: 1px solid #8fc7e8;
        border-radius: 2px;
        background: #c0ddef;
        display: flex;
        flex-flow: row nowrap;
        .detail-icon {
            width: $detailHeight;
            height: $detailHeight;
            padding: 20px;
            .detail-i {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #50bfff;
                color: #c0ddef;
                text-align: center;
                line-height: 30px;
                font-weight: bold;
                font-size: 20px;
            }
        }
        .detail-text {
            width: 100%;
            height: $detailHeight;
            color: #0e8ebe;
            .detail-title {
                width: 100%;
                height: 34px;
                line-height: 40px;
                font-size: 16px;
                font-weight: bold;
            }
            .detail-content {
                width: 100%;
                height: $detailHeight - 34px;
                line-height: 30px;
            }
        }
    }
}
</style>
