<template>
    <div class="basic_data_edit">
        <AssessHeader :currentStep="2" :dataSource="assessHeaderData"></AssessHeader>
        <div class="data_content data-content-form scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form class="demo-form-inline form_row" ref="formdata" :inline="true" :model="formdata.saveJson" size="small" label-width="200px" :rules="rules">
                <el-form-item label="评估记录名称" prop="PGJLMC">
                    <el-input v-model="formdata.saveJson.PGJLMC" placeholder="请输入评估记录名称" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="评估对象" prop="PGDXID">
                    <el-select v-model="formdata.saveJson.PGDXID" placeholder="请选择评估对象" filterable :disabled="!isEdit" @change="handlePgdxChanged">
                        <el-option v-for="(item,index) in dxlxList" :key="index" :label="item.PGDXMC" :value="item.ID"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="开始时间" prop="PGQSSJ">
                    <el-date-picker type="datetime" placeholder="请选择开始时间" v-model="formdata.saveJson.PGQSSJ" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss" :disabled="!isEdit" :picker-options="qssjPickerOptions" @change="handleQssjChanged"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="PGJSSJ">
                    <el-date-picker type="datetime" placeholder="请选择结束时间" v-model="formdata.saveJson.PGJSSJ" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss" :disabled="!isEdit" :picker-options="jssjPickerOptions" @change="handleJssjChanged"></el-date-picker>
                </el-form-item> -->

                <el-form-item label="开始时间" prop="PGQSSJ">
                    <el-date-picker type="date" placeholder="请选择开始时间" v-model="formdata.saveJson.PGQSSJ" style="width: 100%;" value-format="yyyy-MM-dd" :disabled="!isEdit" :picker-options="qssjPickerOptions" @change="handleQssjChanged"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="PGJSSJ">
                    <el-date-picker type="date" placeholder="请选择结束时间" v-model="formdata.saveJson.PGJSSJ" style="width: 100%;" value-format="yyyy-MM-dd" :disabled="!isEdit" :picker-options="jssjPickerOptions" @change="handleJssjChanged"></el-date-picker>
                </el-form-item>

                <el-form-item label="创建人" prop="CJRMC" v-show="!isAdd">
                    <el-input v-model="formdata.saveJson.CJRMC" placeholder="请输入创建人" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="创建时间" prop="CJSJ" v-show="!isAdd">
                    <el-date-picker type="datetime" placeholder="请选择创建时间" v-model="formdata.saveJson.CJSJ" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss" :disabled="true"></el-date-picker>
                </el-form-item>
                <el-form-item label="评估说明" prop="PGJLSM">
                    <el-input type="textarea" :rows="6" v-model="formdata.saveJson.PGJLSM" placeholder="请输入评估说明" :maxlength="400" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../edit-button.js";
import infoMixin from "../info_mixin.js";
import { mapActions } from "vuex";
import AssessHeader from './components/assess_header'
export default {
    name: "assess_record_form",
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            formdata: {
                saveJson: {
                    PGJLMC: "",
                    PGDXID: "",
                    PGJLSM: "",
                    PGQSSJ: null,
                    PGJSSJ: null,
                    CJRID: localStorage.userId,
                    CJRMC: "",
                },
                heritageId: "",
                userName: "",
                userId: ""
            },
            dxlxList: [], // 评估对象

            rules: {
                PGJLMC: [
                    { required: true, message: '请输入评估记录名称', trigger: 'blur' },
                ],
                PGDXID: [
                    { required: true, message: '请选择评估对象', trigger: 'change' }
                ],
            },
            assessHeaderData: {
                title: '请创建评估记录',
                content: '请选择对象类型，填写开始结束时间等创建评估记录。',
            },
            qssjPickerOptions: null,
            jssjPickerOptions: null,
        };
    },
    components: {
        AssessHeader,
    },
    mounted() {
        this.snButton.buttonData[1].isShow = false;
        this.getDxlxList();

        // eslint-disable-next-line no-undef
        $(document).ready(function(){
            let tmp = document.getElementsByClassName("el-textarea__inner");
            if(tmp && tmp.length > 0 && tmp[0].className){
                tmp.forEach(item => {
                    item.className = "el-textarea__inner scrollbar";
                })
            }
        });
    },
    methods: {
        ...mapActions([
            'AddPgjlInfo', //新建/修改评估记录
            "GetPgdxInfoList", //获取评估对象信息列表
        ]),
        handlePgdxChanged(val) {
            let pgdxMc = this.dxlxList.filter((item)=>{
                return item.ID ===val
            })
            if(this.isEdit && pgdxMc && pgdxMc.length>0 && pgdxMc[0].PGDXMC) {
                this.formdata.saveJson.PGJLMC = pgdxMc[0].PGDXMC;
            }
        },
        // 获取评估对象列表
        async getDxlxList() {
            this.dxlxList = [];
            let result = await this.GetPgdxInfoList({
                pageSize: 99999999,	//Int 页面数据量
            });
            this.dxlxList = result.RESULT;
        },
        getFromData() {
            if (this.$route.query.data) {
                Object.assign(this.formdata.saveJson, JSON.parse(this.$route.query.data));
                this.handleDisabledDate();
            }
        },
        async saveData() {
            let res = await this.AddPgjlInfo({
                Info: JSON.stringify(this.formdata.saveJson),
            });
            if (res.ISSUCCESS) {
                this.$message({
                    type: 'success',
                    message: res.RESULTDESCRIPTION
                });
                this.returnList();
            } else {
                this.$message({
                    type: 'error',
                    message: res.RESULTDESCRIPTION
                });
            }
        },
        // eslint-disable-next-line no-unused-vars
        handleQssjChanged(val){
            this.handleDisabledDate();
        },
        // eslint-disable-next-line no-unused-vars
        handleJssjChanged(val){
            this.handleDisabledDate();
        },
        handleDisabledDate(){
            if(this.formdata.saveJson.PGQSSJ){
                let qssj = this.formdata.saveJson.PGQSSJ;
                this.jssjPickerOptions = {
                    disabledDate(time) {
                        // return time.getTime() < new Date(qssj).getTime()+86400*1000;
                        return time.getTime() <= new Date(qssj).getTime();
                    },
                };
            }
            if(this.formdata.saveJson.PGJSSJ){
                let jssj = this.formdata.saveJson.PGJSSJ;
                this.qssjPickerOptions = {
                    disabledDate(time) {
                        // return time.getTime() > new Date(jssj).getTime()-86400*1000;
                        return time.getTime() > new Date(jssj).getTime()-86400*1000;
                    },
                };
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.data-content-form {
    width: 100%;
    height: calc(100% - 150px)!important;
}
</style>