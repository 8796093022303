var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assess-header"},[_c('div',{staticClass:"steps"},_vm._l((_vm.headerData),function(item,index){return _c('div',{key:index,staticClass:"step-single"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"step-content",style:({
                    cursor: _vm.currentStep === item.step ? '' : 'pointer',
                }),on:{"click":function($event){return _vm.handleStepClick(item)}}},[_c('div',{staticClass:"step-title",style:({
                        background:
                            _vm.currentStep === item.step ? '#679fc0' : '#fff',
                        color:
                            _vm.currentStep === item.step ? '#fff' : '#606266',
                    })},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"step-step",style:({
                        color:
                            _vm.currentStep === item.step
                                ? '#679fc0'
                                : '#606266',
                    })},[_vm._v(" "+_vm._s(item.step)+" ")])]),_c('div',{staticClass:"line"})])}),0),_c('div',{staticClass:"detail"},[_vm._m(0),_c('div',{staticClass:"detail-text"},[_c('div',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.dataSource.title || ""))]),_c('div',{staticClass:"detail-content"},[_vm._v(_vm._s(_vm.dataSource.content || ""))])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-icon"},[_c('div',{staticClass:"detail-i"},[_vm._v("i")])])}]

export { render, staticRenderFns }